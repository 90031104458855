import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import HiringForm from '../pages/HiringForm';
import Grid from "@material-ui/core/Grid";
import "../common/styles/HiringStaticPage.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import SiemensImage from '../PrivacyDocument/Siemens190721.jpg';
// using CommonJS modules
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
// using CommonJS modules
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function HiringForm_Static(props) {
    const theme = createMuiTheme({
        overrides: {
            MuiAutocomplete: {
                inputRoot: {
                    '&&[class*="MuiFilledInput-root"] $input': {
                        padding: "0 !important",
                    },
                    height: "55px !important",
                    background: "#E8F8F8",
                    '&$disabled': {
                        backgroundColor: '#454545 !important',
                    },
                }
            },
            MuiFilledInput: {
                root: {
                    "&$focused": { // increase the specificity for the pseudo class
                        color: "#000028",
                        backgroundColor: "#E8F8F8",
                    },
                    "&&&&:hover": {
                        backgroundColor: "#E8F8F8"
                    },
                    "&$disabled": {
                        "pointerEvent": "none",
                        "color": "#707080",
                        "backgroundColor": "#001035",
                        "border": "2px solid #707070",
                        "borderRadius": "0",
                        "&:before": {
                            "borderBottom": "1px solid #001035",
                        },
                        "&:hover:before": {
                            "borderBottom": "1px solid #001035",
                            "borderBottomStyle": "solid"
                        }
                    }
                },
                underline: {
                    "&:before": {
                        borderBottom: "2px solid #E8F8F8",
                        transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                    },
                    "&:after": {
                        borderBottom: "2px solid #E8F8F8",
                        transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                    },
                    "&:hover:before": {
                        borderBottom: "2px solid #00CCCC"
                    },
                    "&:hover:after": {
                        borderBottom: "2px solid #6CD6D6"
                    },
                    "&:focused:after": {
                        borderBottom: "2px solid #6CD6D6"
                    },
                    "&:disabled:before": {
                        border: "none"
                    }
                },
            },

            MuiButtonBase: {
                root: {
                    background: "#E8F8F8",
                    '&$disabled': {
                        backgroundColor: '#454545 !important',
                    },
                },
            },
            MuiDialogActions: {
                root: {
                    background: "#00CCCC"
                }
            },
            MuiDialogTitle: {
                root: {
                    padding: "8px 24px",
                },
            },
            //MuiTextField: {
            //    root: {
            //        color: "#002949",
            //        background: "#000028",
            //        width: '100%'
            //    },
            //},
            MuiFormLabel: {
                root: {
                    //color: "#B3B3BE",
                    color: "#000028",
                    "&$focused": { // increase the specificity for the pseudo class
                        color: "#00CCCC",
                    },
                }
            },
            MuiTextField: {
                root: {
                    color: "#000028",
                    background: "#00CCCC",
                    width: '100%'
                },
            },
            MuiInputLabel: { // Name of the component ⚛️ / style sheet
                root: { // Name of the rule
                    //color: "#B3B3BE",
                    color: "#4C4C68",
                    "&$focused": { // increase the specificity for the pseudo class
                        color: "#00CCCC"
                    }
                },
                asterisk: {
                    color: "red"
                }
            },
            MuiInputBase: {
                root: {
                    color: "#4C4C68"
                },
                input: {
                    backgroundColor: "#EBFBFB",
                    color: "#4C4C68",
                    borderBottom: "0px solid #707070",
                    "&$focused": { // increase the specificity for the pseudo class
                        color: "#E0F9F9",
                        backgroundColor: "#CCF5F5",
                    },
                    "&$hover": {

                        backgroundColor: "#CCF5F5",
                    }
                }
            },
            MuiInput: {
                root: {
                    height: "56px",
                    backgroundColor: "#E8F8F8",
                    color: "#000028",
                    paddingLeft: "0px",
                    "&$focused": {
                        backgroundColor: "#E8F8F8",
                    }
                },
                underline: {
                    "&:before": {
                        borderBottom: "0px solid #FFFFFF",
                        transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                    },
                    "&:after": {
                        borderBottom: "2px solid #FFFFFF",
                        transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                    },
                    "&:hover:before": {
                        borderBottom: "2px solid #FFFFFF"
                    },
                    "&:hover:after": {
                        borderBottom: "2px solid #FFFFFF"
                    },
                    "&:focused:after": {
                        borderBottom: "2px solid  #FFFFFF"
                    },
                    "&:disabled:before": {
                        border: "none"
                    }
                },
            },
            MuiSelect: {
                root: {
                    backgroundColor: "#E8F8F8",
                    color: "#000028"
                }
            },
            MuiFormControl: {
                root: {
                    color: "#FFFFFF",
                    width: "100",
                    paddingLeft: "0px"
                }
            },
            MuiList: {
                root: {
                    backgroundColor: "#CCF5F5"
                }
            },
            MuiListItem: {
                root: {
                    color: "#FFFFFF",
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: "20px",
                    marginBottom: "20px",
                    background: "#CCF5F5"
                }
            },

            MuiToolbar: {
                root: {
                    background: "#000028",
                    color: "#FFFFFF"
                }
            },
            MuiTablePagination: {
                root: {
                    borderBottom: "none"
                },
                toolbar: {
                    borderTop: `1px solid #FFFFFF`,
                    "& MuiTypography-root": {
                        color: `#00CCCC !important`
                    }
                },
                menuItem: {
                    color: "#FFFFFF"
                },
                selectIcon: {
                    color: "#00CCCC"
                },
                input: {
                    backgroundColor: "#000028"
                }
            },
            MuiTypography: {
                caption: {
                    color: "#000028"
                }
            },
            MuiTableSortLabel: {
                root: {
                    color: "#00CCCC",
                    "&$focused": {
                        color: "#00CCCC",
                    },
                    "&$hover": {
                        color: "#FFFFFF"
                    },
                    "&$active": {
                        color: "#00CCCC"
                    },
                    "& div:hover": {
                        color: "#00CCCC"
                    }
                },
                icon: {
                    color: `#00CCCC !important`,
                    "&$hover": {
                        color: "#FFFFFF"
                    },
                    "&$active": {
                        color: "#00CCCC"
                    },
                },
                iconDirectionDesc: {
                    color: `#00CCCC !important`,
                    "&$hover": {
                        color: "#FFFFFF"
                    },
                    "&$active": {
                        color: "#00CCCC"
                    },
                },
                iconDirectionAsc: {
                    color: `#00CCCC !important`,
                    "&$hover": {
                        color: "#FFFFFF"
                    },
                    "&$active": {
                        color: "#00CCCC"
                    },
                }
            },
            MuiTableCell: {
                root: {
                    color: "#FFFFFF !important"
                },
                body: {
                    background: "#000028",
                    color: "#FFFFFF"
                }
            },
            //MuiFormControl: {
            //    root: {
            //        paddingLeft: "0px"
            //    }
            //},
            MuiSvgIcon: {
                root: {
                    color: "#6a7272"
                }
            },
            MuiPickersBasePicker: {
                pickerView: {
                    background: "#000028"
                },
                background: "#000028",
                container: {
                    background: "#000028"
                }
            },
            MuiPickersDatePickerRoot: {
                toolbar: {
                    background: "#000028"
                }
            },
            MuiPickersCalendarHeader: {
                iconButton: {
                    backgroundColor: "none",
                    color: "#FFFFFF"
                },
                switchHeader: {
                    color: "#FFFFFF"
                },
                daysHeader: {
                    color: "#FFFFFF"
                },
                dayLabel: {
                    color: "#FFFFFF"
                }
            },
            MuiPickersYear: {
                root: {
                    color: "#FFFFFF"
                }
            },
            //MuiPickersBasePicker: {
            //    container: {
            //        background: "#000028"
            //    }
            //},
            MuiPickersDay: {
                day: {
                    color: "#FFFFFF"
                }
            },
            MuiButton: {
                textPrimary: {
                    color: "#FFFFFF"
                }
            },
            MuiTable: {
                root: {
                    backgroundColor: "#000028"
                }
            },
            MuiFormHelperText: {
                contained: {
                    backgroundColor: "white",
                    marginBottom: "-10px !important",
                    marginLeft: "0px !important",
                    marginRight: "0px !important",
                    color: "red !important",
                    lineHeight: "0.5 !important"
                },
            },
        }
    });
    const [isReadStatic, setisReadStatic] = React.useState(false);

    //const [numPages, setNumPages] = React.useState(null);
    //const [pageNumber, setPageNumber] = React.useState(1);
    const handleNext = async () => {
        setisReadStatic(true);
    };
    //function onDocumentLoadSuccess({ numPages }) {
    //    setNumPages(numPages);
    //}

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(props);
    }, []);

    if (isReadStatic) {
        var routes = (
            <Switch>
                <Route exact path="/HiringForm" render={() =>
                    <HiringForm
                        loginDetails={props.loginDetails}
                        UserFullName={props.UserFullName}
                        userId={props.userid}
                        applicantId={props.applicantid}
                        roleId={props.roleId}
                        url={"http://localhost:54008/CandidateData"}
                        loader={props.loader}
                        token={props.token}
                    />
                }
                />
                <Redirect from="/HiringFormStatic" to="/HiringForm" />
            </Switch>
        );
        return (
            <BrowserRouter>
                <div>

                    <main className="">{routes}</main>
                </div>
            </BrowserRouter>
        );
    }
    else {
        return (
            <ThemeProvider theme={theme}>
                <div className="master-layout-block">

                    <form className="d-flex jc-center">
                        <Grid container item xs={12} className="d-flex fd-column box-shadow px-2 mb-2 bdr-radius ">
                            <Grid container className="d-flex jc-space-around fd-row">
                                <Grid item xs={12} sm={12} className="d-flex mt-2">
                                    <div>
                                        <img className="ImageBlock" src={SiemensImage} alt="Logo" />
                                    </div>


                                </Grid>
                                <Grid item xs={12} sm={12} className="hiringstaticgrid">
                                    <div className="headingBlock"> <p className="heading1">Engagement of Trade Apprentices</p>
                                        <p className="heading2">As per The Apprentices Act, 1961</p></div>
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    <b>Applications are invited from candidates for “Electrician” and “Fitter” trade under the
                                       Apprenticeship Training scheme at Kalwa Works, Siemens Ltd., under the Apprentices Act -1961.</b>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="paddingBottom mt-1"><b>Training details:</b></div>
                                    <div> Duration: 2 years (full-time)</div>
                                    <div className="paddingBottom">  The training will be as per the apprenticeship training scheme (ATS) 
                                        <br />prescribed by Directorate General of Training and German Dual Vocational
                                        Education & Training methodology. At the end of apprenticeship,
                                        apprentices will have to appear for All India Trade Test (AITT).</div>
                                    <div> <b>Eligibility criteria</b></div>
                                    <div>  <b>  1. Minimum Educational Qualification:</b></div>
                                    <div> The candidate must have passed SSC / equivalent examination in<br /> the 1 st attempt with minimum
                                    60% marks (aggregate). Additionally,<br /> he / she
                                    must have obtained marks as specified below:</div>
                                    <div>  • Science: 60% and above<br />
                                        • Maths: 60% and above<br />
                                        • English: 50% and above</div>

                                    <div> SSC candidates with vocational subjects will be
                                    given preference. </div>

                                    <div className="paddingBottom mt-1"> 
                                    <div>
                                            <b>Important Note:</b>
                                            <div>
                                                1. Only candidate appearing for 10th grade exam in March 2025 are eligible for admission.
                                            </div>
                                            <div>
                                                2. Enter your 9th grade marks. Once the 10th grade exam result is declared kindly fill the marks on admission portal under 10th grade exam.
                                            </div>
                                    </div></div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="mt-1"><b>2. Age Criteria:</b></div>
                                    <div className="paddingBottom"> • Only those born after 1st January 2009 can apply.<br /> </div>

                                    <div><b>3. Minimum Physical Standards:</b></div>
                                    <div className="paddingBottom"> • Height: 150 cm   • Weight: 45 kg</div>
                                    <div><b>Stipend details:</b></div>
                                    <div className="paddingBottom"> The apprentices engaged will get a stipend
                                    during the training period as per The Apprentices Act 1961 and as per the company policy. Stipend will be paid for all 2 years.</div>
                                    <div><b>Selection Process:</b></div>
                                    <div className="paddingBottom">Initial shortlist for written test to be based on SSC marks, followed by Written Test, Interview and
                                     Medical Fitness Test.</div>
                                    <div> <b>Application Process:</b></div>
                                    <div>  Application form will be accepted till 30th June 2025.<br />
                                        Once shortlisted, online written test date will be shared via email.
                                    </div>
                                </Grid>



                                <Grid container spacing={2} className="d-flex jc-space-around fd-row mt-1">
                                    <Grid item xs={12} sm={12} className="nextpagebtn mb-2">
                                        <Button xs={5} sm={4} className="ns-btn-primary mt-2" value={"Next"} onClick={handleNext}>
                                            Next Page
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </form>
                </div>

            </ThemeProvider>
        );
    }
}